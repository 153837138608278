import moment from 'moment';
import React from 'react';
import { DateCard } from 'components/DateCard';
import { HelpModals } from 'components/HelpModals';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import { ExportRegistrations } from './ExportRegistrations';
import { PrintRegistrations } from './PrintRegistrations';

export const RegistrationsHeader = ({
  data,
  ticket_id,
  division,
  registration_type,
}) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: 40,
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DateCard item={data?.getEvent} margin={'margin: 0 10px 0 0'} />

        <Text type="heading" fontSize={32} color={theme.colors.secondary}>
          {data.getEvent.name}
        </Text>
        {'postponed' === data.getEvent.status ? (
          <img
            style={{
              width: '20%',
              height: 'auto',
              display: 'block',
              marginLeft: 20,
              transform: 'rotate(10deg)',
            }}
            src="https://d3294qt0f4hbwl.cloudfront.net/postponed.png"
            alt="postponed-stamp"
          />
        ) : null}
        {'cancelled' === data.getEvent.status ? (
          <img
            style={{
              width: '20%',
              height: 'auto',
              display: 'block',
              marginLeft: 20,
            }}
            src="https://d3294qt0f4hbwl.cloudfront.net/cancelled.png"
            alt="cancelled-stamp"
          />
        ) : null}
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ExportRegistrations
          date={
            data?.getEvent?.isMultiDay
              ? `${data?.getEvent?.fullMonth} ${
                  data?.getEvent?.listDates
                } ${moment(data?.getEvent?.end_date).format('YYYY')} `
              : data?.getEvent?.fullDate.replace(/,/g, ' ')
          }
          event={data?.getEvent}
          ticketFilter={ticket_id}
          division={division}
          registration_type={registration_type}
        />

        <PrintRegistrations
          date={
            data?.getEvent?.isMultiDay
              ? `${data?.getEvent?.fullMonth} ${
                  data?.getEvent?.listDates
                } ${moment(data?.getEvent?.end_date).format('YYYY')} `
              : data?.getEvent?.fullDate.replace(/,/g, ' ')
          }
          event={data?.getEvent}
          ticketFilter={ticket_id}
          division={division}
          registration_type={registration_type}
        />

        <HelpModals />
      </div>
    </div>
  );
};

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import styled, { withTheme } from 'styled-components';
import { Button } from 'components/Button';
import { ContainerWrapper } from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { SearchInput } from 'components/Form/SearchInput';
import { HelpModals } from 'components/HelpModals';
import Icon from 'components/Icon';
import LineHeightText from 'components/LineHeightText';
import { ConfirmModal } from 'components/Modal';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
import { TransactionModal } from 'components/Transactions';
import {
  withEventYearFilterContext,
  YearDropdown,
} from 'components/YearDropdown';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import { TitleContainer } from 'pages/track/Contact/style';
import { logDevError } from 'shared/alerts';
import { IssueCreditModal } from '../issueCreditModal';
import ExportOpenCreditsReport from './ExportOpenCredits/ExportOpenCreditsReport';
import { PrintOpenCreditsReport } from './ExportOpenCredits/PrintOpenCreditsReport';
import DeleteCredit from './gql/delete-credit';
import { useGetUserOpenCreditsByAccount } from './gql/get-user-open-credits-by-account';

export const CreditButton = styled.div`
  padding: 0;
  margin-left: auto;
  margin-right: 40px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: 0;
  }
`;

const FilterContainer = styled.div`
  min-width: 150px;
`;

const OpenCreditsDetail = withEventYearFilterContext((props) => {
  const { deleteCredit, theme, location, match } = props;
  const [credits, setCredits] = useState();
  const [count, setCount] = useState();
  const [search, setSearch] = useState('');

  const { id, id2 } = useParams();
  const userId = parseInt(id2);
  const accountId = parseInt(id);
  const thisYear = Number(moment().format('YYYY'));
  const [year, setYear] = useState(thisYear);

  const [showIssueCredit, setShowIssueCredit] = useState(false);
  const [shouldDisplayDeleteConfirmation, setShouldDisplayDeleteConfirmation] =
    useState(false);
  const [credit, setCredit] = useState();

  const [currentTransaction, setCurrentTransaction] = useState(null);

  const { res, loading } = useGetUserOpenCreditsByAccount();

  useEffect(() => {
    if (res && res.getUserOpenCreditsByAccount) {
      setCredits(res?.getUserOpenCreditsByAccount?.results);
      setCount(res?.getUserOpenCreditsByAccount?.count);
    }
  }, [res]);

  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  const columns = [
    {
      label: 'Ticket Holder',
      key: 'ticketHolder',
    },
    {
      label: 'Credit Total',
      key: 'credit',
    },
    {
      label: 'Credit #',
      key: 'creditNum',
    },
    {
      label: 'Issued',
      key: 'issued',
    },
    {
      label: 'Issued By',
      key: 'issuedBy',
    },
    {
      label: 'Original Total',
      key: 'originalTotal',
    },
    {
      label: 'Track Credit',
      key: 'trackCredit',
    },
    {
      label: 'Fee Credit',
      key: 'feeCredit',
    },
    {
      label: 'Purchase',
      key: 'purchase',
    },
    {
      label: '',
      key: 'actions',
    },
  ];

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  function renderRows(credit) {
    const {
      id,
      user,
      amount,
      date,
      issued_by,
      original_amount,
      track_credit,
      fee_credit,
      transaction,
    } = credit;

    const userName = user.formatted_name;
    const issuer = issued_by.formatted_name;

    return {
      ticketHolder: userName,
      credit: amount.toString(),
      creditNum: id,
      issued: date,
      issuedBy: issuer,
      originalTotal: original_amount,
      trackCredit: track_credit,
      feeCredit: fee_credit,
      purchase: (
        <div
          style={{
            color: theme.colors.primary,
            cursor: 'pointer',
          }}
          onClick={() => setCurrentTransaction(transaction)}
          role="presentation"
        >
          <LineHeightText>
            {' '}
            {transaction && transaction.id ? 'View' : ''}
          </LineHeightText>
        </div>
      ),
      actions: (
        <>
          <Icon
            onClick={() => {
              setCredit({ id: id, user_id: user.id });
              setShouldDisplayDeleteConfirmation(true);
            }}
            size={18}
            color={props.theme.colors.primary}
            icon="trash"
          />
        </>
      ),
    };
  }

  async function handleDeleteConfirm() {
    try {
      const response = await deleteCredit(credit, {
        account_id: Number(accountId),
        user_id: Number(userId),
        year: year,
      });
      if (response && !response.errors) {
        toast.success('Credit deleted successfully');
        setShouldDisplayDeleteConfirmation(false);
      } else {
        toast.error('Error Deleting Credit');
      }
    } catch (error) {
      logDevError(error);
      toast.error('Error Deleting Credit');
    }
  }

  return loading ? (
    <div />
  ) : (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'Row',
          justifyContent: 'end',
          marginTop: 20,
          marginRight: 40,
        }}
      >
        <ExportOpenCreditsReport headerText={''} year={year} />

        <PrintOpenCreditsReport headerText={''} year={year} />

        <HelpModals />
      </div>
      <Container>
        <ContainerHeader>
          <ContainerWrapper>
            <TitleContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, marginTop: 10 }}
              >
                Open Credits
              </Text>
              <FilterContainer>
                <YearDropdown
                  showLabel={false}
                  onSelect={(year) => setYear(year)}
                />
              </FilterContainer>
              <div style={{ marginLeft: 15 }}>
                <SearchInput
                  placeholder="Search Credits"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  value={search}
                />
              </div>
            </TitleContainer>
            <CreditButton>
              <Button
                onClick={() => setShowIssueCredit(true)}
                buttonStyle={{ height: 35 }}
              >
                {'Issue Credit'}
              </Button>
            </CreditButton>
          </ContainerWrapper>
        </ContainerHeader>
        <div style={{ padding: 2 }}>{props.children}</div>
        {!credits ? (
          <div />
        ) : (
          <div style={{ padding: 20 }}>
            <Table items={credits} columns={columns} renderRows={renderRows} />
            <Pagination
              count={count}
              perPage={15}
              currentPage={currentPage || 1}
            />
          </div>
        )}
      </Container>
      <IssueCreditModal
        setIsVisible={setShowIssueCredit}
        isVisible={showIssueCredit}
        isTrackAdmin={false}
        vieEmployeeSearch={false}
        customerId={userId ?? ''}
        credits={credits}
        setCredits={setCredits}
      />

      <ConfirmModal
        confirmText="Delete"
        cancelText="Cancel"
        hideModal={() => setShouldDisplayDeleteConfirmation(false)}
        isVisible={shouldDisplayDeleteConfirmation}
        onConfirm={() => handleDeleteConfirm()}
        title="Do you want to delete this Credit?"
      />

      {currentTransaction ? (
        <TransactionModal
          admin={location.pathname.includes('/admin/transactions/')}
          match={match}
          currentTransaction={currentTransaction}
          close={() => {
            setCurrentTransaction(null);
          }}
        />
      ) : null}
    </>
  );
});

export default withTheme(DeleteCredit(OpenCreditsDetail));
